$icon-pc: 70px; // Wrapper
$icon-size-pc: 22px; // Play icon
$icon-mobile: 50px; // Wrapper
$icon-size-mobile: 15px; // Play icon
.video-play-trigger {
  height: $icon-mobile;
  width: $icon-mobile;
  background-color: $color-cream;
  border-radius: 50%;
  transition: 0.2s transform ease-in;
  @include breakpoint($landscape-up) {
    height: $icon-pc;
    width: $icon-pc;
  }
  .videojs-video & {
    height: $icon-mobile;
    width: $icon-mobile;
    @include breakpoint($landscape-up) {
      height: $icon-pc;
      width: $icon-pc;
    }
  }
  &::before {
    content: ' ';
    display: block;
    background: url(#{$base-theme-path}/img/icons/src/play-video.svg) no-repeat;
    background-position: center center;
    width: $icon-size-mobile;
    height: $icon-size-mobile;
    margin: 0 auto;
    top: 50%;
    transform: translate(0, -50%);
    position: relative;
    @include breakpoint($landscape-up) {
      height: $icon-size-pc;
      width: $icon-size-pc;
    }
  }
  &:hover {
    transform: translate(-50%, -50%) scale(1.1, 1.1);
    @if $is-rtl==true {
      transform: translate(50%, -50%) scale(1.1, 1.1);
    }
  }
}

#colorbox.colorbox--video {
  margin: 0;
  padding: 0;
  #cboxLoadedContent {
    padding: 0;
    margin: auto;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    height: auto !important;
    z-index: 100;
  }
  .videojs-video {
    z-index: 100;
  }
  video {
    display: block;
    width: 100%;
    max-height: 100%;
  }
}

#cboxOverlay.colorbox--video {
  margin: 0;
  padding: 0;
}


/*
 * Replicate the spinning green circle normally found on the landing play icon.
 * The .vjs-loading-spinner shows up on the video itself, which is usually
 * hidden when playing inline.
 */

.vjs-loading-spinner {
  border: 0;
  // Hide loading spinner for mobile
  display: none;
  @include breakpoint($landscape-up) {
    display: block;
  }
  &::before {
    display: none;
  }
  &::after {
    position: absolute;
    top: -5px;
    #{$ldirection}: -5px;
    content: ' ';
    display: block;
    margin: 0;
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    border-radius: 50%;
    border: 11px solid transparent;
    border-color: $color-green transparent transparent transparent !important;
    animation: video-loading-circle 1.5s linear infinite;
  }
}

.vjs-fullscreen-control {
  &.vjs-button {
    // Hide full screen button for mobile
    display: none;
    @include breakpoint($landscape-up) {
      display: block;
    }
  }
}

// Mantle video styles
$mobile-landscape: 0 (max-height 480px) (orientation landscape) !default;
@keyframes video-loading-circle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.videojs-video {
  // i..e video element
  position: relative;
  background: $color-black;
  padding-top: 56.25%;
  &__player {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    #{$ldirection}: 0;
    z-index: 0;
  }
  &__play {
    position: absolute;
    top: 50%;
    #{$ldirection}: 50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
    z-index: 99;
    @if $is-rtl==true {
      transform: translate(50%, -50%);
    }
    &:hover {
      cursor: pointer;
    }
    @include breakpoint($landscape-up) {
      font-size: 70px;
    }
  }
  &__landing {
    overflow: hidden;
    position: absolute;
    top: 0;
    #{$ldirection}: 0;
    height: 100%;
    width: 100%;
    z-index: 9;
    &__inner {
      position: absolute;
      top: 0;
      #{$ldirection}: 0;
      width: 100%;
      height: 100%;
    }
    &__image {
      picture {
        opacity: 0;
        transition: opacity 0.9s ease-in-out;
      }
      img {
        position: absolute;
        max-width: none;
        max-height: 100%;
        #{$ldirection}: 50%;
        height: 100%;
        width: auto;
        transform: translateX(-50%);
        @if $is-rtl==true {
          transform: translateX(50%);
        }
        aspect-ratio: 4 / 5;
        object-fit: cover;
        @include breakpoint($landscape-up) {
          aspect-ratio: 16 / 9;
        }
      }
    }
  }
  &.videojs-video--loading &__landing {
    .video-play-trigger {
      &::after {
        position: absolute;
        top: -5px;
        #{$ldirection}: -5px;
        content: ' ';
        display: block;
        margin: 0;
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        border-radius: 50%;
        border: 11px solid transparent;
        border-color: $color-green transparent transparent transparent;
        animation: video-loading-circle 1.5s linear infinite;
        .colorbox--video & {
          width: calc(100% - 10px);
          height: calc(100% - 10px);
        }
      }
    }
  }
  .video-js {
    display: contents;
    @include breakpoint($landscape-up) {
      min-height: auto;
    }
    @include breakpoint($mobile-landscape) {
      max-width: 100vw;
      height: calc(100vh - 20px);
      padding-top: 0;
    }
    @include breakpoint($mobile-landscape) {
      max-width: 100vw;
      height: calc(100vh - 20px);
      padding-top: 0;
    }
    .vjs-control-bar {
      background-color: transparent;
    }
    .vjs-big-play-button,
    &.vjs-has-started .vjs-big-play-button {
      display: none;
    }
  }
}

.video-detail-cuepoint-target {
  display: block;
  @include breakpoint($landscape-up) {
    display: none;
  }
}

// relative for video card positioning
.video-detail__video-wrapper {
  position: relative;
  @include breakpoint($medium-down) {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    #{$ldirection}: 0;
  }
}
