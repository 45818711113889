.video-block--fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: z('peak');
}

.vjs-full-window {
  .site-header {
    display: none;
  }
  .video-block {
    display: block;
    height: 100vh;
    background: $color-white;
    &__media,
    &__media-video {
      height: 100%;
    }
  }
  .vjs-fullscreen {
    // This takes the video to full screen, which is why it's left and not RTL support
    left: 0;
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
    background: $color-white;
    overflow: hidden;
    display: block;
    z-index: z('video-module', 'video-fullscreen');
  }
  .mantle-custom-text {
    display: none;
  }
  .liveperson-concierge {
    display: none;
  }
}

.video-js {
  z-index: z('video-module', 'video-container');
  &::before {
    content: '';
    width: 100%;
    height: 60px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: z('video-module', 'video-mini-overlay');
    background-color: transparent;
  }
  > iframe {
    position: absolute;
    z-index: z('video-module', 'video-frame');
  }
  .vjs-control-bar {
    position: relative;
    z-index: z('video-module', 'video-controls');
    height: 80px;
    top: calc(100% - 59px);
  }
  button.vjs-picture-in-picture-control {
    display: none;
  }
}

.videojs-video {
  &--loading {
    .videojs-video__landing__image {
      display: none;
    }
  }
}
