@import '../../../../scss/theme-bootstrap';
@import './sass/styles';
@import './sass/player-elements';
@import './sass/mobile-fullscreen';

.video-block {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 auto;
  &__content {
    display: flex;
    flex-direction: column;
  }
  &__media-video {
    position: relative;
    @include breakpoint($medium-down) {
      height: auto;
      width: 100%;
      aspect-ratio: 4 / 5;
    }
  }
  &__media {
    margin-bottom: 60px;
  }
  .vjs-big-play-button {
    display: none !important; // Hide youtube icon
  }
  .videojs-video {
    @include breakpoint($medium-down) {
      height: 100%;
    }
    .video-js {
      .vjs-control-bar {
        .vjs-load-progress {
          opacity: 0;
        }
        .vjs-progress-holder {
          height: 1px;
        }
        .vjs-play-progress {
          height: 3px;
          top: -1px;
          &::before {
            display: none;
          }
        }
        .vjs-volume-bar {
          top: 14px;
          height: 1px;
        }
        .vjs-volume-level {
          top: -1px;
          &::before {
            display: none;
          }
        }
        .vjs-current-time,
        .vjs-duration {
          display: none;
          @include breakpoint($landscape-up) {
            display: block;
            font-size: 12px;
            line-height: 5em;
          }
        }
        .vjs-current-time {
          padding-#{$rdirection}: 0;
          order: 2;
          .vjs-current-time-display {
            &::after {
              content: '|';
              margin: 5px;
            }
          }
        }
        .vjs-duration {
          padding-#{$ldirection}: 0;
          order: 3;
        }
        .vjs-remaining-time {
          display: none;
        }
        // Ordered elements
        .vjs-play-control {
          display: block;
          @include breakpoint($landscape-up) {
            display: block;
            order: 1;
          }
          &.vjs-playing {
            bottom: 5px;
            @include breakpoint($landscape-up) {
              bottom: 1px;
            }
          }
        }
        .vjs-progress-control {
          display: flex;
          order: 4;
          margin-top: -4px;
          @include breakpoint($landscape-up) {
            margin-top: -1px;
          }
        }
        .vjs-volume-panel {
          order: 5;
          &:hover {
            width: 4em;
            .vjs-volume-control {
              display: none;
            }
          }
        }
        .vjs-fullscreen-control {
          display: block;
          order: 6;
        }
      }
      &.vjs-layout-tiny,
      &.vjs-layout-x-small {
        .vjs-progress-control {
          display: flex;
        }
      }
    }
  }
  &.control-bar-color-dark {
    .videojs-video .video-js .vjs-control-bar {
      color: $color-black;
      .vjs-load-progress {
        background: $color-black;
      }
      .vjs-progress-holder {
        background-color: $color-black;
      }
      .vjs-play-progress {
        background-color: $color-black;
      }
      .vjs-volume-level {
        background-color: $color-black;
      }
      .vjs-volume-bar {
        background: $color-black;
      }
      .vjs-control {
        .vjs-icon-placeholder {
          &::before {
            color: $color-black;
          }
        }
      }
      .vjs-duration-display,
      .vjs-current-time-display {
        color: $color-black;
      }
    }
  }
  &.control-bar-color-light {
    .videojs-video .video-js .vjs-control-bar {
      color: $color-cream;
      .vjs-load-progress {
        background: $color-cream;
      }
      .vjs-progress-holder {
        background-color: $color-cream;
      }
      .vjs-play-progress {
        background-color: $color-cream;
      }
      .vjs-volume-level {
        background-color: $color-cream;
      }
      .vjs-volume-bar {
        background: $color-cream;
      }
      .vjs-control {
        .vjs-icon-placeholder {
          &::before {
            color: $color-cream;
          }
        }
      }
      .vjs-duration-display,
      .vjs-current-time-display {
        color: $color-cream;
      }
    }
  }
  // Styles for dark and light control bar background
  &.control-bar-background-dark {
    .videojs-video .video-js .vjs-control-bar {
      background-color: $color-black;
    }
  }
  &.control-bar-background-light {
    .videojs-video .video-js .vjs-control-bar {
      background-color: $color-white;
    }
  }
  // Styles for dark and light play icon color
  &.play-icon-color-dark {
    .videojs-video .video-play-trigger {
      background-color: $color-dark-grey;
    }
  }
  &.play-icon-color-light {
    .videojs-video .video-play-trigger {
      background-color: $color-black;
      &::before {
        filter: invert(1);
      }
    }
  }
  &.extra-sound-control-dark {
    .video-block__media-video-extra-sound-control {
      .icon {
        fill: $color-black;
      }
    }
  }
  &.extra-sound-control-light {
    .video-block__media-video-extra-sound-control {
      .icon {
        fill: $color-cream;
      }
    }
  }
  &.playing-video {
    .video-block__media-video-extra-sound-control {
      .video-block__media-video-extra-sound-speaker {
        display: block;
      }
      &.audio-off {
        .video-block__media-video-extra-sound-speaker-mute {
          display: block;
        }
        .video-block__media-video-extra-sound-speaker {
          display: none;
        }
      }
    }
  }
  .video-block__media-video-extra-sound-control {
    cursor: pointer;
    font-size: 25px;
    margin: 25px;
    position: absolute;
    .icon--speaker_mute {
      display: none;
    }
    .icon {
      &::before {
        font-size: 30px;
      }
    }
  }
  &.extra-sound-control-top-right {
    .video-block__media-video-extra-sound-control {
      #{$rdirection}: 0;
      top: 0;
    }
  }
  &.extra-sound-control-top-left {
    .video-block__media-video-extra-sound-control {
      #{$ldirection}: 0;
      top: 0;
    }
  }
  &.extra-sound-control-bottom-right {
    .video-block__media-video-extra-sound-control {
      #{$rdirection}: 0;
      bottom: 0;
    }
    .vjs-control-bar {
      display: flex;
    }
  }
  &.extra-sound-control-bottom-left {
    .video-block__media-video-extra-sound-control {
      #{$ldirection}: 0;
      bottom: 0;
    }
    .vjs-control-bar {
      display: flex;
    }
  }
  &--fullscreen {
    .videojs-video {
      z-index: z('video-module', 'video-frame');
    }
    .vjs-youtube .vjs-poster {
      opacity: 0;
    }
    .videojs-video__player .vjs-fullscreen>div>iframe[style] {
      top: 0 !important;
      height: 100vh !important;
    }
    .videojs-video__landing {
      visibility: hidden;
    }
  }
}

.chapterized-video {
  .mantle-video-player__cuepoint-reactions {
    display: flex;
    justify-content: space-around;
    opacity: 0;
    position: absolute;
    transition: opacity 1s;
    top: 65px;
    width: 100%;
    z-index: z('video-module', 'video-reactions');
    @include breakpoint($landscape-up) {
      justify-content: space-between;
      padding: 0 40px;
    }
  }
  .video-cuepoint {
    display: none;
  }
  &__details {
    cursor: pointer;
  }
  &__copies {
    color: $white;
    @include breakpoint($landscape-up) {
      margin-top: 15px;
    }
    .chapter-copies__description {
      display: none;
      opacity: 0.8;
      @include breakpoint($landscape-up) {
        display: block;
      }
    }
  }
  &__progress-bar {
    padding: 5px 0;
    position: relative;
    .progress-bar {
      &__holder,
      &__progress {
        background-color: $white;
        height: 2px;
      }
      &__holder {
        opacity: 0.5;
        width: 100%;
      }
      &__progress {
        #{$ldirection}: 0;
        position: absolute;
        top: 5px;
        width: 0;
        z-index: z('video-module', 'video-controls');
      }
    }
  }
  .vjs-marker {
    visibility: hidden;
  }
  .video-interacted {
    &:hover {
      .mantle-video-player__cuepoint-reactions {
        opacity: 1;
      }
    }
    &.chapters-hovered,
    &.paused {
      .mantle-video-player__cuepoint-reactions,
      .vjs-control-bar {
        @include breakpoint($landscape-up) {
          opacity: 1;
        }
      }
      .vjs-control-bar {
        height: 60px;
      }
    }
    &.chapters-hovered {
      .mantle-video-player__cuepoint-reactions,
      .vjs-control-bar {
        opacity: 1;
      }
    }
    .vjs-youtube {
      .vjs-iframe-blocker {
        @include breakpoint($landscape-up) {
          display: block;
        }
      }
    }
  }
  .chapter-width {
    &--two,
    &--three {
      font-size: 14px;
      @include breakpoint($landscape-up) {
        font-size: 15px;
      }
    }
    &--four,
    &--five,
    &--six {
      font-size: 12px;
      @include breakpoint($landscape-up) {
        font-size: 15px;
      }
      &.chapterized-video__details--mobile {
        .chapter-copies__title {
          display: none;
        }
        &.active {
          .chapter-copies__title {
            display: block;
          }
        }
      }
    }
    &--two {
      width: 48%;
    }
    &--three {
      width: 31.33%;
    }
    &--four {
      width: 23%;
    }
    &--five {
      width: 18%;
    }
    &--six {
      width: 14.66%;
    }
  }
}

.expandable_video_content {
  .chapterized-video {
    .video-block__content {
      display: none;
    }
    .mantle-video-player__cuepoint-reactions {
      top: 65px;
    }
  }
}
