.video-block .videojs-video {
  &__player {
    .vjs-fullscreen {
      &:not(.vjs-ios-native-fs) {
        position: fixed;
        #{$ldirection}: 0;
        top: 0;
        z-index: z('video-module', 'video-fullscreen');
      }
      >div>iframe {
        position: fixed;
        top: 0;
        //important tags required here to override mantle global settings
        /* stylelint-disable */
        width: 100vw !important;
        height: 100vh !important;
        #{$ldirection}: 0 !important;
        z-index: z('video-module', 'video-frame');
        position: fixed !important;
        @include breakpoint($landscape-up) {
          top: 218px !important;
          height: calc(100vh - 277px) !important;
        }
        /* stylelint-enable */
      }
      &.vjs-paused,
      &.vjs-user-active,
      &.vjs-paused,
      &:hover {
        .vjs-control-bar {
          transition: all ease-in-out 0.5s;
          overflow: hidden;
          opacity: 1;
          height: 60px;
          z-index: z('video-module', 'video-controls');
          width: 100vw;
          position: fixed;
          bottom: 0;
        }
      }
      .vjs-control-bar {
        @include breakpoint($landscape-up) {
          bottom: 0;
          opacity: 0;
          transition: all ease-in-out 0.5s;
          height: 0;
        }
      }
    }
    .vjs-controls-enabled {
      .vjs-control-bar {
        width: 100%;
        height: 60px;
        justify-content: space-between;
        bottom: -3px;
        @include breakpoint($landscape-up) {
          bottom: 0;
          opacity: 0;
          transition: all ease-in-out 0.5s;
          height: 0;
          overflow: hidden;
        }
        @include breakpoint($landscape-up) {
          padding: 0 10px;
        }
        .vjs-progress-control,
        .vjs-time-control {
          @include breakpoint($landscape-up) {
            opacity: 0;
            transition: all ease-in-out 0.5s;
          }
        }
        &:hover {
          @include breakpoint($landscape-up) {
            opacity: 1;
          }
          .vjs-progress-control,
          .vjs-time-control {
            @include breakpoint($landscape-up) {
              opacity: 1;
              transition: all ease-in-out 0.5s;
            }
          }
        }

        .vjs-icon-placeholder {
          &::before {
            position: absolute;
            height: 19px;
            width: 19px;
            top: 28px;
            #{$ldirection}: 14px;
            transform: translateY(-50%);
          }
        }
        .vjs-play-control {
          span.vjs-icon-placeholder {
            &::before {
              content: '';
              background: url(#{$base-theme-path}img/icons/src/pause-video.svg) no-repeat;
            }
          }
          &.vjs-paused {
            span.vjs-icon-placeholder {
              &::before {
                background: url(#{$base-theme-path}img/icons/src/play-video.svg) no-repeat;
              }
            }
          }
        }
        .vjs-fullscreen-control {
          span.vjs-icon-placeholder {
            &::before {
              content: '';
              background: url(#{$base-theme-path}img/icons/src/expand-video.svg) no-repeat;
            }
          }
        }
        .vjs-mute-control {
          span.vjs-icon-placeholder {
            &::before {
              content: '';
              background: url(#{$base-theme-path}img/icons/src/sound-off.svg) no-repeat;
            }
          }
          &.vjs-vol-0 {
            span.vjs-icon-placeholder {
              &::before {
                background: url(#{$base-theme-path}img/icons/src/sound-on.svg) no-repeat;
              }
            }
          }
        }
        .vjs-volume-control {
          display: none;
        }
      }
    }
    .vjs-has-started {
      &.vjs-playing {
        &.vjs-user-inactive {
          .vjs-control-bar {
            opacity: 1;
            z-index: z('video-module', 'video-controls');
            @include breakpoint($landscape-up) {
              opacity: 0;
            }
          }
        }
      }
    }
    &:hover,
    &.vjs-user-inactive {
      .vjs-control-bar {
        @include breakpoint($landscape-up) {
          opacity: 0.4;
          height: 60px;
          transition: all ease-in-out 0.75s;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
}
